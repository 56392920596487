export const tableColumnsTopCreators = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Address" ,
    accessor: "address",
  }

];
